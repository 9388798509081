(<template>
  <div class="nav-dropdown">
    <button class="nav-dropdown__btn"
            :class="{'nav-dropdown__btn--active': interpretationDropdownActive}"
            @click="showInterpretationDropdown">{{ $gettext('Interpretation') }}</button>
    <div v-if="interpretationDropdownActive"
         v-click-outside="closeDropdown"
         class="dropdown-list">
      <div class="dropdown-list__wrapper">
        <router-link v-for="(link, index) in filteredLinks"
                     :key="index"
                     :to="link.action"
                     :class="{'favourite-interp-link': link.iconClass === 'favorite-interpreters-icon'}"
                     class="dropdown-list__item dropdown-link">
          <div class="dropdown-link__image"
               :class="link.iconClass"></div>
          <span class="dropdown-link__text">{{ link.text }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    data() {
      return {
        interpretationDropdownActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCanShowStatistics',
        'isEmailVerificationRequired',
        'showPowerBIPage'
      ]),
      assignmentsLink() {
        return this.$makeRoute({
          name: 'BuyerAllAssignments',
          query: {
            view: 'current',
            sort: 1,
            page: 1
          }
        });
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'BuyerCalendar',
          query: {
            view: 'week',
            date: this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      capacityLink() {
        return this.$makeRoute({
          name: 'BuyerCapacity',
          query: {
            view: 'day',
            date: this.$moment().format('YYYY-MM-DD'),
            page: 1
          }
        });
      },
      interpretersLink() {
        return this.$makeRoute({
          name: 'BuyerInterpreters',
          query: {
            page: 1,
            availability_date: this.$moment().format('YYYY-MM-DD'),
            langFrom: '3',
            relation: true
          }
        });
      },
      statisticLink() {
        return this.showPowerBIPage
          ? this.$makeRoute({name: 'BuyerPowerBI'})
          : this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'interpretation'}, params: {chartId: 'line_type'}});
      },
      links() {
        return [
          {
            text: this.$gettext('Interpretation assignments'),
            type: 'router-link',
            iconClass: 'interpretation-assignments-icon',
            action: this.assignmentsLink,
            condition: true
          },
          {
            text: this.$gettext('Calendar'),
            type: 'router-link',
            iconClass: 'calendar-icon',
            action: this.calendarLink,
            condition: true
          },
          {
            text: this.$gettext('Interpretation statistics'),
            type: 'router-link',
            iconClass: 'interpretation-statistics-icon',
            action: this.statisticLink,
            condition: this.userCanShowStatistics
          },
          {
            text: this.$gettext('Favorite interpreters'),
            type: 'router-link',
            iconClass: 'favorite-interpreters-icon',
            action: this.interpretersLink,
            condition: true
          },
          {
            text: this.$gettext('Book specific interpreter'),
            type: 'router-link',
            iconClass: 'book-specific-interpreter-icon',
            action: this.capacityLink,
            condition: !this.isEmailVerificationRequired
          }
        ];
      },
      filteredLinks() { return this.links.filter((link) => link.condition); }
    },
    watch: {
      $route() {
        this.closeDropdown();
      }
    },
    methods: {
      showInterpretationDropdown() {
        this.interpretationDropdownActive = !this.interpretationDropdownActive;
      },
      closeDropdown() {
        this.interpretationDropdownActive = false;
      }
    }
  };
</script>

<style scoped>
hr {
  height: 1px;
  border: none;
  background-color: #e4e6eb;
}
</style>
