(<template>
  <div class="nav-dropdown">
    <button class="nav-dropdown__btn"
            :class="{'nav-dropdown__btn--active': translationDropdownActive}"
            @click="showTranslationDropdown">{{ $pgettext('translate', 'Translation') }}</button>
    <div v-if="translationDropdownActive"
         v-click-outside="closeDropdown"
         class="dropdown-list">
      <div class="dropdown-list__wrapper">
        <router-link v-for="(link, index) in filteredLinks"
                     :key="index"
                     :to="link.action"
                     class="dropdown-list__item dropdown-link">
          <div class="dropdown-link__image"
               :class="link.iconClass"></div>
          <span class="dropdown-link__text">{{ link.text }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    data() {
      return {
        translationDropdownActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userCanShowStatistics']),
      links() {
        return [
          {
            text: this.$gettext('Translation assignments'),
            type: 'router-link',
            iconClass: 'translation-assignments-icon',
            action: this.$makeRoute({name: 'BuyerAllProjects'}),
            condition: true
          },
          {
            text: this.$gettext('Translation statistics'),
            type: 'router-link',
            iconClass: 'translation-statistics-icon',
            action: this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'translation'}, params: {chartId: 'line_type'}}),
            condition: this.userCanShowStatistics
          }
        ];
      },
      filteredLinks() { return this.links.filter((link) => link.condition); }
    },
    watch: {
      $route() {
        this.closeDropdown();
      }
    },
    methods: {
      showTranslationDropdown() {
        this.translationDropdownActive = !this.translationDropdownActive;
      },
      closeDropdown() {
        this.translationDropdownActive = false;
      }
    }
  };
</script>
