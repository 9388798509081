(<template>
  <footer class="s-footer">
    <div class="s-footer__desktop-wrapper">
      <div class="s-footer__logo"></div>
      <p class="s-footer__copy">{{ copyright }}</p>
      <locale-select class="s-footer__locale-select" />
      <div class="s-footer__external-links-cont">
        <a target="_blank"
           :href="privacyLink"
           class="s-footer__external-link">{{ $gettext('Privacy') }}</a>
        <a target="_blank"
           :href="termsLink"
           class="s-footer__external-link">{{ $gettext('Terms & Conditions') }}</a>
        <a class="s-footer__external-icon-link s-footer__external-icon-link--twitter"
           target="_blank"
           :href="twitterLink"></a>
        <a class="s-footer__external-icon-link s-footer__external-icon-link--facebook"
           target="_blank"
           :href="facebookLink"></a>
        <a class="s-footer__external-icon-link s-footer__external-icon-link--linkedin"
           target="_blank"
           :href="linkedinLink"></a>
      </div>
    </div>
    <div class="s-footer__mobile-wrapper">
      <div v-for="(menu,index) in bottomMenu"
           :key="index"
           class="s-footer_menu-wrapper">
        <router-link v-if="menu.type === 'router-link' && menu.condition"
                     :to="menu.action"
                     :class="menu.classNames">{{ menu.name }}</router-link>
      </div>
      <button class="burger-btn"
              :class="{'is-opened-mobile-nav burger-btn--open': mobileNavState, 'is-sub-page': isSubPage}"
              @click="toggleMobileNav">
        <span class="burger-btn__first-line"></span>
        <span class="burger-btn__second-line"></span>
        <span class="burger-btn__third-line"></span>
        <span class="burger-btn__fourth-line"></span>
        {{ $gettext('My account') }}
      </button>
    </div>
  </footer>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    data() {
      return {
        currencyList: [{
          id: 1,
          name: 'NOK'
        }]
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCurrencyId',
        'userIsSeller',
        'userHasInterpretation'
      ]),
      ...mapGetters('EditProfileStore', ['getPersonInfoModalData']),
      ...mapState('EditProfileStore', {
        profileData: (state) => state.profileData
      }),
      ...mapState('s_DashboardStore', {
        newInvitation: (state) => state.newInvitation
      }),
      invitationMenuClasses() {
        return this.newInvitation ? 'mobile-link mobile-link--invitations new-job-invitation' : 'mobile-link mobile-link--invitations';
      },
      bottomMenu() {
        if (this.userIsSeller && !this.userHasInterpretation) {
          return this.translationOnlyBottomMenu;
        } else if (!this.userIsSeller && this.userHasInterpretation) {
          return this.interpretationOnlyBottomMenu;
        } else if (this.userHasInterpretation && this.userIsSeller) {
          return this.bothBottomMenu;
        }
      },
      interpretationOnlyBottomMenu() {
        return [
          {
            name: this.$gettext('Interpretation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--interpretation',
            action: this.$makeRoute({name: 'ServerHome'}),
            condition: true
          },
          {
            name: this.$gettext('Calendar'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--calendar',
            action: this.calendarLink,
            condition: true
          },
          {
            name: this.$gettext('Assignments'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--assignments',
            action: this.assignmentsLink,
            condition: true
          },
          {
            name: this.$gettext('Invitations'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--invitations',
            action: this.$makeRoute({name: 'ServerAllInvitations'}),
            condition: true
          }
        ];
      },
      translationOnlyBottomMenu() {
        return [
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--new-translation',
            action: this.projectsLink,
            condition: true
          }
        ];
      },
      bothBottomMenu() {
        return [
          {
            name: this.$gettext('Interpretation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--interpretation',
            action: this.$makeRoute({name: 'ServerHome'}),
            condition: true
          },
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--new-translation',
            action: this.projectsLink,
            condition: true
          },
          {
            name: this.$gettext('Calendar'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--calendar',
            action: this.calendarLink,
            condition: true
          },
          {
            name: this.$gettext('Invitations'),
            type: 'router-link',
            classNames: this.invitationMenuClasses,
            action: this.$makeRoute({name: 'ServerAllInvitations'}),
            condition: true
          }
        ];
      },
      projectsLink() {
        return this.$makeRoute({
          name: 'ServerAllProjects',
          query: {
            available: 'new',
            accepted: 'active'
          }
        });
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'ServerCalendar',
          query: {
            view: 'day',
            date: this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'ServerAllAssignments',
          query: {
            view: 'current',
            sortConfirmedBy: 1,
            sortAvailableBy: 1,
            pageConfirmedBy: 1,
            pageAvailableBy: 1
          }
        });
      },
      mobileNavState() {
        return this.$store.getters.showMobileNav;
      },
      isSubPage() {
        return this.$store.state.subPage;
      },
      needUserInfoUpdate() {
        const data = this.getPersonInfoModalData;
        return this.profileData ? !data.gender || !data.postcode || !data.country : false;
      },
      copyright() { return this.$getString('footer', 'copyright'); },
      facebookLink() { return this.$getString('social', 'facebookLink'); },
      twitterLink() { return this.$getString('social', 'twitterLink'); },
      linkedinLink() { return this.$getString('social', 'linkedinLink'); },
      privacyLink() { return this.$getString('domainData', 'privacyLink'); },
      termsLink() { return this.$getString('domainData', 'termLink'); }
    },
    methods: {
      toggleMobileNav() {
        this.$store.commit('toggleMobileNav');
      }
    }
  };
</script>

<style>
  .s-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  .input-focused .s-footer {
    position: relative;
    margin-bottom: -50px;
  }

  .s-footer__desktop-wrapper {
    display: flex;
    width: 100%;
    min-height: 50px;
  }

  .s-footer__mobile-wrapper {
    display: none;
  }

  .s-footer__logo {
    display: block;
    width: 50px;
    background-image: url(~@assets/imgs/logos/single_logo_gray.svg);
    background-position: 50% 50%;
    background-size: 20px auto;
    background-repeat: no-repeat;
  }

  .s-footer__copy {
    display: inline-block;
    align-self: center;
    width: auto;
    padding-right: 25px;
    color: #a7acbe;
    font-size: 12px;
  }

  .s-footer__locale-select,
  .s-footer__locale-select .sk-select {
    align-self: center;
    width: 110px;
    height: 35px;
    border: 0;
    color: #a7acbe;
    text-align: right;
  }

  .s-footer__locale-select:hover {
    background-color: #f0ecf6;
    box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
  }

  .s-footer__locale-select .sk-select__value {
    background-color: transparent;
    font-size: 12px;
    line-height: 33px;
  }

  .s-footer__locale-select:hover .sk-select__value {
    color: #000;
  }

  .s-footer__locale-select .sk-select__typing-input {
    background-color: transparent;
    color: #000;
  }

  .s-footer__locale-select .sk-select__arrow {
    height: 35px;
    background-color: transparent;
  }

  .s-footer__locale-select .sk-select__arrow::before,
  .s-footer__locale-select .sk-select__arrow::after {
    border-top-color: #a7acbe;
  }

  .s-footer__locale-select .sk-select__list {
    top: auto;
    bottom: 100%;
    border: 0;
    color: #000;
    font-size: 12px;
  }

  .s-footer__locale-select .sk-select__list-item {
    padding-top: 14px;
  }

  .s-footer__external-links-cont {
    display: flex;
    margin-left: auto;
    padding-right: 70px;
    color: #a7acbe;
    font-size: 12px;
  }

  .s-footer__external-link {
    display: inline-block;
    align-self: center;
    width: auto;
    padding: 0 12px;
    text-align: center;
  }

  .s-footer__external-link:hover,
  .s-footer__external-link:focus {
    color: #000;
    text-decoration: underline;
  }

  .s-footer__external-icon-link {
    position: relative;
    display: inline-block;
    width: 44px;
  }

  .s-footer__external-icon-link::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 7px;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .s-footer__external-icon-link:hover::before,
  .s-footer__external-icon-link:focus::before {
    background-color: #f0ecf6;
  }

  .s-footer__external-icon-link--twitter::before {
    background-image: url(~@assets/imgs/social_links_icons/twitter_footer_icon.svg);
    background-position: 50% 65%;
    background-size: 16px auto;
  }

  .s-footer__external-icon-link--facebook::before {
    background-image: url(~@assets/imgs/social_links_icons/facebook_footer_icon.svg);
    background-size: 10px auto;
  }

  .s-footer__external-icon-link--linkedin::before {
    background-image: url(~@assets/imgs/social_links_icons/linkedin_footer_icon.svg);
    background-size: 15px auto;
  }

  .s-footer__external-icon-link--twitter:hover::before,
  .s-footer__external-icon-link--twitter:focus::before {
    background-image: url(~@assets/imgs/social_links_icons/twitter_footer_icon_black.svg);
    background-position: 50% 50%;
    background-size: 16px auto;
  }

  .s-footer__external-icon-link--facebook:hover::before,
  .s-footer__external-icon-link--facebook:focus::before {
    background-image: url(~@assets/imgs/social_links_icons/facebook_footer_icon_black.svg);
    background-size: 8px auto;
  }

  .s-footer__external-icon-link--linkedin:hover::before,
  .s-footer__external-icon-link--linkedin:focus::before {
    background-image: url(~@assets/imgs/social_links_icons/linkedin_footer_icon_black.svg);
    background-size: 15px auto;
  }

  @media (max-width: 767px) {
    .s-footer {
      bottom: -60px;
      min-height: 130px;
    }

    .input-focused .s-footer {
      bottom: 0;
      min-height: initial;
    }

    .s-footer__desktop-wrapper {
      display: none;
    }

    .s-footer__mobile-wrapper {
      display: flex;
      justify-content: space-around;
      width: 100%;
      min-height: 50px;
      color: #000;
    }

    .s-footer_menu-wrapper {
      position: relative;
      width: 25%;
    }

    .is-sub-page.is-opened-mobile-nav {
      color: #646d8c;
      color: var(--color-primary);
    }

    .is-sub-page.is-opened-mobile-nav span {
      background-color: #646d8c;
      background-color: var(--color-primary);
    }
  }
</style>
<style scoped>
@media (max-width: 767px) {
  .mobile-link {
    width: 100%;
  }
}
</style>
