(<template>
  <footer class="b-footer-mobile">
    <router-link :to="$makeRoute({name: 'BuyerHome'})"
                 class="mobile-link-footer mobile-link--home">{{ $gettext('Home') }}</router-link>
    <router-link :to="$makeRoute({name: 'BuyerAllAssignments', query: {view: 'current', sort: 1, page: 1}})"
                 class="mobile-link-footer mobile-link--interpretation">{{ $gettext('Interpretation') }}</router-link>
    <button class="mobile-link-footer book-btn-mobile"
            @click="toggleMobileDrawer('book')">
      {{ $gettext('Book') }}
    </button>
    <router-link :to="$makeRoute({name: 'BuyerAllProjects'})"
                 class="mobile-link-footer mobile-link--new-translation">{{ $pgettext('translation', 'Translation') }}</router-link>
    <button class="mobile-btn--burger mobile-link-footer"
            :class="{'burger-btn--active': isMenuOpen}"
            @click="toggleMobileDrawer('menu')">
      {{ $gettext('Menu') }}
    </button>
  </footer>
</template>)

<script>
  export default {
    computed: {
      isMenuOpen() { return this.$store.getters.mobileDrawerType === 'menu' && this.$store.getters.showMobileDrawer; },
    },
    methods: {
      toggleMobileDrawer(type) {
        this.$store.commit('toggleMobileDrawer', {force: false, drawerType: type});
      }
    }
  };
</script>

<style>
.b-footer-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 12;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 76px;
  border-top: 1px solid #e4e6eb;
  background-color: #fff;
}

.mobile-link-footer {
  position: relative;
  width: 19.5%;
  min-height: 100%;
  padding-top: 45px;
  padding-bottom: 15px;
  background-position: 50% calc(100% - 35px);
  background-size: 24px auto;
  background-repeat: no-repeat;
  font-size: 10px;
  text-align: center;
  white-space: nowrap;
}

.book-btn-mobile {
  position: relative;
}

.book-btn-mobile::before {
  content: "";
  position: absolute;
  top: -10px;
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #ff5b24;
  background-image: url(~@assets/imgs/nav_icons/mobile/plus_icon_white.svg);
  background-position: center center;
  background-repeat: no-repeat;
}

.mobile-link-footer.router-link-active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}

.mobile-link-footer.router-link-active {
  color: #000;
}

.mobile-link--home {
  background-image: url(~@assets/imgs/nav_icons/mobile/home_footer_icon.svg);
}

.mobile-link--interpretation {
  background-image: url(~@assets/imgs/nav_icons/mobile/interpretation_footer_icon.svg);
}

.mobile-btn--burger {
  background-image: url(~@assets/imgs/nav_icons/mobile/burger_footer_icon.svg);
}

.mobile-link--new-translation {
  background-image: url(~@assets/imgs/nav_icons/mobile/translation_footer_icon.svg);
}

.mobile-link--home.router-link-active {
  background-image: url(~@assets/imgs/nav_icons/mobile/home_footer_icon_black.svg);
}

.mobile-link--interpretation.router-link-active {
  background-image: url(~@assets/imgs/nav_icons/mobile/interpretation_footer_icon_black.svg);
}

.mobile-link--new-translation.router-link-active {
  background-image: url(~@assets/imgs/nav_icons/mobile/translation_footer_icon_black.svg);
}

.burger-btn--active {
  background-image: url(~@assets/imgs/nav_icons/mobile/burger_footer_icon_black.svg);
}

.burger-btn--active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}
</style>
